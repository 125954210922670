@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Challenge {
	@include defaultPage();
	@include flex('', '', 'row');
	background-image: url('../../assets/images/background-roomOverview.svg');
	background-size: cover;
    background-position: bottom right;
	background-repeat: no-repeat;
	height: 100%;

	.Challenge-languageButton {
		position: absolute;
		top: 1em;
		right: 1em;
	}

	.Challenge-sidebar {
		@include flex('', '', 'column');
		margin-left: 1em;
		margin-top: 1em;
		position: relative;
		z-index: 99;
		
		.Challenge-sidebarLogo:hover {
			cursor: pointer;
		}

		.Challenge-sidebarLogo {
			width: 11em;
			height: 7em;
			margin-bottom: 1em;
			margin-left: -1em;
			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-position: center center;
			background-image: url('../../assets/images/datadyst-logo.svg');

			&.en {
				background-image: url('../../assets/images/datadyst-logo-english.svg');
			}
		}
	
		.Challenge-sidebarRoomButtons {
			margin-top: 1.5em;
			margin-bottom: 4em;
			width: 10em;
		}
	}

	.Challenge-wrapper {
		@include flex('', '', 'column');
		margin-left: 4em;
		margin-top: 2em;
		width: 100%;

		.Challenge-header {
			@include flex('', '', 'row');
			position: relative;
			text-align: center;
			z-index: 2;
			
			.Challenge-headerStatus	{
				@include flex('', 'center', 'row');
				position: relative;

				.Challenge-line {
					width: 100%;
					position: absolute;
					z-index: 1;
					height: 0.05em;
					min-height: 1px;
					padding-left: 0.25em;
					padding-right: 0.25em;
					background-color: $turquoise;
				}
		
				.Challenge-challengeStatus {
					position: relative;
					z-index: 2;
					display: inline-block;
					width: 1.25em;
					height: 1.5em;
					background-image: url('../../assets/images/hexagon/hexagon-empty.svg');
					background-size: contain;
					background-position: center center;
					background-repeat: no-repeat;
					&--selected {background-image: url('../../assets/images/hexagon/hexagon-challenge-selected.svg');}
					&--completed {background-image: url('../../assets/images/hexagon/hexagon-challenge-completed.svg');}
					&--notFirst {margin-left: 0.5em;}
				}
			}
			.Challenge-counter {
				@include no-select();
				color: $turquoise;
				@include align-self(center);
				margin-left: 1em;
				min-width: 3em;
			}
		}
		
		.Challenge-body {
			position: relative;
			width: 100%;
			margin-top: 2em;
		}
	}

	.Challenge-logoWrapper {
		@include flex('space-between', '', 'column');
		.Challenge-hexagon {
			@include flex('', '', '');
			position: relative;
			right: 7em;
			top: 12em;
			z-index: 0;
		}
	
		.Challenge-datatilsynetLogo {
			@include flex('', '', '');
			@include align-self(flex-end);
			position: relative;
			min-width: 16.48em;
			min-height: 3.6848em;
			width: 16.48em;
			height: 3.6848em;
			bottom: 3em;
			right: 3em;
			background-size: cover;
			background-image: url('../../assets/images/icon-datatilsynet-logo.svg');
		}
	}
}
