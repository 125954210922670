@import '../styles/fonts';
@import '../styles/variables';
@import '../styles/mixins';
@import '../styles/animations';
.App {
	position: relative;
	height: 100%;
	min-height: 100%;
	overflow: auto;
	background-color: $blue-dark;
	@include flex($justify:"center", $align:"flex-start", $direction:"");

	.App-wrap {
		position: relative;
		width: 100%;
		height: 100%;
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		width: 50%;
		background-color: $orange;
	}
}

@media (min-aspect-ratio: 16/9) {
	.App .App-wrap {
		height: 100%;
		width: 76.8em;
		font-size: 2.315vh;
	}
}
