/* BODY TEXT */
@font-face {
	font-family: 'tt norms';
	src: url('../assets/fonts/TTNorms-Regular.ttf');
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: 'tt norms';
	src: url('../assets/fonts/TTNorms-Bold.ttf');
	font-style: normal;
	font-weight: bold;
}

@font-face {
	font-family: 'tt norms';
	src: url('../assets/fonts/TTNorms-ExtraBold.ttf');
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: 'tt norms';
	src: url('../assets/fonts/TTNorms-Light.ttf');
	font-style: normal;
	font-weight: 300;
}

// font-family: Frutiger, sans-serif;
