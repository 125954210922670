@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Quiz {
	width: 27em;
	&--paused {.Quiz-answer {cursor: not-allowed;}}

	.Quiz-header {
		color: $text-light;
		position: relative;
		border-radius: 0.5em;
		z-index: 1;
		background-size: cover;

		.Quiz-headerContent {
			min-height: 10em;
			position: relative;
			padding: 1.5em 2em 1em 1em;
			border-radius: 0.7em;
			background-color: rgba($blue-light, 40%);

			.Quiz-questionHeader {
				@include no-select();
				text-align: center;
				padding-top: 0.5em;
				padding-bottom: 0.5em;
				background-color: $blue-dark;
				border-radius: 0.5em;
				box-shadow:  0.3em 0.3em 0.3em #0000002a;
				width: 9.5em;
				position: absolute;
				left: -1.5em;
				top: -1em;
				.Quiz-questionHeaderText {
					font-size: 1.25em;
					font-weight: bold;
				}
			}

			.Quiz-question {
				@include no-select();
				font-size: 1.125em;
				font-weight: normal;
				line-height: 1.2;
				text-align: center;
				color: $text-light;
				p {display: inline; margin: 0;}
			}
			.Quiz-question {
				&--left {
					text-align: left;
					padding: 0.25em 1.25em 0.5em 1.25em;
				}
			}
		}
	}
	
	.Quiz-body {
		position: relative;
		margin-top: 2em;
		z-index: 0;
	
		.Quiz-instructions {
			@include no-select();
			margin-bottom: 1em;
			margin-left: 1em;
			position: relative;
			text-align: start;
			font-weight: bold;
			color: $text-light;
		}
			
		.Quiz-answers {
			color: $text-light;
			position: relative;

			&--flexWrap {
				@include flex('', '', 'column');
				flex-wrap: wrap;
				height: 14em;
			}

			.Quiz-answer:hover {
				cursor: pointer;
			}
			
			.Quiz-answer {
				@include flex('center', 'center');
				@include no-select();
				line-height: 1.1;
				padding: 0.5em;
				text-align: center;
				background-color: $turquoise;
				border-color: $turquoise-light;
				border-style: solid;
				border-width: 0.1em;
				border-radius: 0.7em;
				margin-bottom: 0.5em;
				max-height: 4em;
				min-height: 4em;
				@include box-shadow(0, 0.1876em, 0.25em, 0, rgba(#000000, 0.16));
				@include translate(0, 0);
				p {display: inline; margin: 0;}

				&--twoColumns {
					@include flex('', '', '');
					width: 12.5em;
					margin-right: 2em;
					position: relative;
					flex: auto;
					flex-wrap: wrap;
				}
				
				&--animateCorrect {
					background-color: $green;
					border-color: $green-light;
					-webkit-animation: bounce 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
					-webkit-animation-fill-mode: forwards;
					animation: bounce 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
					animation-fill-mode: forwards;
				}
				&--correct {
					background-color: $green;
					border-color: $green-light;
				}

				&--animateWrong {
					background-color: $red;
					border-color: $red-light;
					-webkit-animation: wobble 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
					-webkit-animation-fill-mode: forwards;
					animation: wobble 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
					animation-fill-mode: forwards;
				}
				&--wrong {
					background-color: $red;
					border-color: $red-light;
				}
			}
		}
	}
	
	.Quiz-imageThumb {
		position: relative;
		text-align: center;
		
		img {
			vertical-align: middle;
			height: 3.5em;
		}
		.Quiz-imageThumbZoom {
			display: inline-block;
			vertical-align: middle;
			margin-left: 0.5em;
			width: 2.5em;
			height: 2.5em;
			background-image: url('../../../assets/images/icon-zoom.svg');
			background-size: contain;
			background-position: center right;
			background-repeat: no-repeat;
		}
	}
	
	.Quiz-clues {
		padding-top: 1em;
		font-size: 1.125em;
		text-align: center;
		color: $text-dark;
	}
	
	.Quiz-clueInfo {
		text-align: left;
		margin-bottom: 0.5em;
		text-transform: uppercase;
		span {
			font-weight: bold;
			color: $pink;
			text-transform: uppercase;
		}
	}
	.Quiz-clueText {
		position: relative;
		text-align: left;
		padding-left: 1em;
		padding-bottom: 0.125em;
		p {display: inline; margin: 0;}
		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0.1em;
			width: 0.55em;
			height: 0.55em;
			border-radius: 0.55em;
			background-color: $pink;
		}
	}
	.Quiz-getClueBtn {
		margin-top: 0.5em;
		font-weight: bold;
		color: $text-dark;
		text-transform: uppercase;
		span {
			color: $pink;
			text-decoration: underline;
		}
	}
	
	.Quiz-image {
		width: 100%;
		height: 15em;
		background-size: 100% auto;
		background-position: bottom center;
		background-repeat: no-repeat;
	}
	
	.Quiz-image + .Quiz-answers,
	.Quiz-clues + .Quiz-answers {
		padding-top: 0;
	}

}
