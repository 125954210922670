@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.GameLogo {
	display: inline-block;
	width: 16em;

	.GameLogo-logo {
		background-image: url('../../assets/images/datadyst-logo.svg');
		width: 15em;
		height: 12em;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;

		&.en {
			background-image: url('../../assets/images/datadyst-logo-english.svg');
		}
	}
}
