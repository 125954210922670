@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.RoomOverview {
	position: relative;
	@include defaultPage();
	@include flex('center', '', 'row');
	height: 100%;
	background-image: url('../../assets/images/background-roomOverview.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom right;

	.RoomOverview-languageButton {
		position: absolute;
		top: 1em;
		right: 1em;
	}

	.RoomOverview-wrapper {
		@include flex('space-between', 'center', 'column');
		@include no-select();
		width: 100%;

		.RoomOverview-welcome {
			@include flex('', '', 'row');
			top: 2em;
			left: 10em;
			position: relative;
			line-height: 1;
			color: $text-light;
			text-align: center;
			.RoomOverview-welcomeText {
				font-size: 2em;
				font-weight: normal;
			}
		}
		.RoomOverview-contentContainer {
			@include flex('', '', 'row');
			position: relative;
			text-align: center;
			margin-left: 11em;
		}
		.RoomOverview-chooseRooms {
			@include flex('', '', 'column');
			position: relative;
			text-align: center;
			bottom: 8em;
			left: 7em;
			color: $text-light;
			
			.RoomOverview-chooseRoom {
				margin-top: -1em;
				font-size: 1.5em;
				font-weight: normal;
			}

			.RoomOverview-rooms {
				@include flex('', 'baseline', '');
				max-width: calc(100% - 2em);
				margin: 0.5em auto 0 auto;
				text-align: center;

				.RoomOverview-room {
					margin-right: 1em;
				}
			}
		}
	}
	
	.RoomOverview-logoWrapper {
		@include flex('space-between', '', 'column');
		.RoomOverview-hexagon {
			@include flex('', '', '');
			pointer-events: none;
			position: relative;
			@include align-self(flex-end);
			right: 7em;
			top: 12em;
			z-index: 0;
		}
		.RoomOverview-datatilsynetLogo {
			@include flex('', '', '');
			@include align-self(flex-end);
			position: relative;
			min-width: 16.48em;
			min-height: 3.6848em;
			width: 16.48em;
			height: 3.6848em;
			right: 3em;
			bottom: 3em;
			background-size: cover;
			background-image: url('../../assets/images/icon-datatilsynet-logo.svg');
		}
	}
}

.RoomOverview-asLink {
	position: absolute;
	position: absolute;
	bottom: 1em;
	left: 7em;
	
	a {
		color: white;
		font-size: 0.7em;
		&:hover,
		&:active,
		&:visited {
			color: white;
		}
	}

}

.TrophyBtn {
	position: absolute;
	bottom: 10em;
	left: 2em;
	width: 6em;
	color: white;
	padding: 1em;
	background-color: rgb(119, 11, 11);
}

.ResetBtn {
	position: absolute;
	bottom: 6em;
	left: 2em;
	width: 6em;
	color: white;
	padding: 1em;
	background-color: rgb(119, 11, 11);
}

