$border-radius: 0.25em;

/* Colors */
$blue-dark: #14273F;
$blue-light: #019DDF;
$turquoise: #0F9BD7;
$turquoise-light: #0BFFFF;

$turquoise-select: #62D0FF;
$turquoise-select-light: #D8F6FF;

$red: #E4335D;
$red-light: #F85E68;

$orange: #C69E54;

$green: #5AB592;
$green-light: #72FAC6;

$bronze: #954C2E;
$bronze-light: #B95F51;
$silver: #A3A3A3;
$silver-light: #D3D0CD;
$gold: #C69E54;
$gold-light: #FFE200;

// old
$beige-light: #FFFBEF;
$beige: #FDEEC6;
$beige-dark: #F5D992;

$orange-light: #FFC13F;
$orange-dark: #F57D3A;

$brown: #5B160F;
$pink: #D8217E;

$olive: #6B6C26;
$olive-dark: #51541F;


$text-dark: $brown;
$text-light: white;