@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.CookieConsent {
	.CookieConsent-consentWrapper {
		@include flex('center', 'center', 'column');
		position: absolute;
		bottom: 1em;
		left: 1em;
		width: 4em;

		.CookieConsent-consentToggleContainer {
			cursor: pointer;
			@include flex('', 'center', '');
			z-index: 99;
			position: relative;
			width: 3em;
			height: 2.5em;
			background-image: url('../../assets/images/cookie/cookies-notAccepted.svg');
			background-size: cover;

			&.Accepted {
				background-image: url('../../assets/images/cookie/cookies-accepted.svg');
			}
		}
		.CookieConsent-consentToggleText {
			white-space: pre-line;
			color: $text-light;
			font-size: 0.7em;
			text-align: center;
		}
	}

	.CookieConsent-popup {
		z-index: 99;
		display: none;
		position: absolute;
		width: 39em;
		bottom: 25%;
		left: 25%;
		color: $text-dark;
		background: $blue-dark;
		border-style: solid;
		border-width: 0.2em;
		border-color: $turquoise-light;
		border-radius: 1em;

		&.show {
			display: block;
		}

		.CookieConsent-textWrapper {
			position: relative;
			color: $text-light;
			padding: 1em;
			font-weight: normal;

			.CookieConsent-textHeader {
				font-size: 1.5em;
				margin-bottom: 1rem;
			}

			.CookieConsent-textBody {
				font-size: 0.7em;
			}
		}

		.CookieConsent-buttonWrapper {
			@include flex('', '', 'row');
			position: relative;
			.CookieConsent-button:hover {
				cursor: pointer;
			}
			.CookieConsent-button {
				@include flex('center', 'center', '');
				margin-left: 1em;
				width: 11em;
				height: 2.5em;
				border-radius: 0.5em;
				margin-bottom: 1em;
				
				.CookieConsent-buttonText {
					color: $text-light;
					padding: 0.25em 0;
					font-size: 1em;
					font-weight: bold;
					text-transform: uppercase;
				}
			}
			.Ok {
				background-color: $green
			}
			.Cancel {
				background-color: $red;
			}
		}
	}
}
