@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.WarningButton {
	position: relative;

	.WarningButton-wrapper {
		pointer-events: none;
		&.Show {
			@include flex('center', 'center', 'row');
		}

		-webkit-animation: 1s fadeIn , 1s 2s fadeOut forwards;
		animation: 1s fadeIn , 1s 2s fadeOut forwards;
		display: none;
		position: absolute;
		width: fit-content;
		z-index: 98;
		height: 2em;
		left: 4.25em;
		top: 1.25em;
		padding: 0.5em;
		border-radius: 0.5em;
		background-color: rgb($red, 0.8);

		.WarningButton-warning {
			white-space: nowrap;
			color: $text-light;
		}

		.WarningButton-warningLock {
			width: 1em;
			height: 1em;
			background-image: url('../../assets/images/icon-lock.svg');
			background-size: contain;
			background-repeat: no-repeat;
		}
	}

	.WarningButton-button {
		@include flex('center', '', '');
		background-color: $orange;
		border-radius: 0.5em;
		width: 100%;
		height: 2em;
		margin-bottom: 0.5em;
		.WarningButton-text {
			@include flex('', '', '');
			@include no-select();
			@include align-self(center);
			padding-left: 1em;
			padding-right: 1em;
			color: $text-light;
			font-size: 1.5em;
		}
	}

	.WarningButton-button:hover{
		cursor: pointer;
		filter: brightness(0.85);
	}

	.NotActive {
		@include opacity(0.4);
	}
}