@import 'styles/fonts';
@import 'styles/variables';
@import 'styles/mixins';
@import 'styles/animations';
html {
  position: relative;
  // font-size: 1.3021vw; // 25px at screen width ~1920px (2500/1920 ~ 1.3021)
  font-size: 1.3021vw;
  height: 100%;
}

body {
  position: relative;
  font-size: 1em;
  font-weight: bold;
  margin: 0;
  padding: 0;
  height: 100%;
  width:100%;
  color: #363636;
}

#root {
  height: 100%;
}

* {box-sizing: border-box;
  font-family: 'tt Norms';
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;}

button {
  font-weight: bold;
  background-image: none;
  -webkit-tap-highlight-color: transparent;
  @include no-select();
  &:focus {
    outline: 0;
  }
  &::-moz-focus-inner {
    border: 0;
  }
}

ul, ol {
	padding-inline-start: 1.5em;
	margin: 0;
}

input,
input:focus,
textarea,
textarea:focus {
  resize: none;
  font-size: inherit;
  @include appearance(none);
}