@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Popup {
	visibility: hidden;
	pointer-events: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	color: $text-dark;
	background-color: rgba($beige-light, 0.95);
	z-index: 20;
	opacity: 0;
	@include transition('opacity', 0.33s, 'ease-in-out');
	&--show {
		opacity: 1;
		visibility: visible;
		pointer-events: all;
	}
}

.Popup-body {
	position: relative;
	width: 100%;
	background-color: $beige;
	padding: 4em 2em 2.5em 2em;
	text-align: center;
	color: $text-dark;
}

.Popup-close {
	position: absolute;
	top: 0;
	right: 0;
	width: 3.5em;
	height: 3.5em;
	background-image: url('../../assets/images/icon-x.svg');
	background-size: 1.75 auto;
	background-position: center center;
	background-repeat: no-repeat;
	cursor: pointer;
}

.Popup-title {
	font-size: 1.5em;
	font-weight: 700;
	text-transform: uppercase;
	padding: 0 1em 1em 1em;
}

.Popup-text {
	font-size: 1.5625em;
	line-height: 1.2;
	padding-bottom: 2em;
}

.Popup-points {
	font-size: 1.5625em;
	font-weight: 900;
}

.Popup-feedback {
	width: 100%;
	// background-color: rgba(#484740, 0.79);
	background-color: $brown;
	padding: 2em;
	color: white;
	text-align: left;
	p {
		text-align: center;
		font-size: 1.5625em;
		margin-top: 0;
	}
	> div {
		position: relative;
		font-size: 1.125em;
		padding-left: 1em;
		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0.45em;
			width: 0.5em;
			height: 0.5em;
			border-radius: 0.5em;
			background-color: $beige;
		}
	}
}

.Popup-image {
	width: 100%;
	img {
		width: 100%;
	}
}

.Popup-buttons {
	text-align: center;
	padding: 0 1em;
	@include flex(space-between, center);
}
.Popup-button {
	display: inline-block;
	font-size: 1.25em;
	font-weight: bold;
	text-transform: uppercase;
	background-color: $orange;
	padding: 0.75em 2em;
}

/* Game intro popup */
.Popup.Popup--intro {
	background-color: $beige;
	background-image: 
		url('../../assets/images/background-plant2.png'), 
		url('../../assets/images/background-plant1.png'), 
		url('../../assets/images/background-grass1.png'); 
	background-size: auto 100%, 100% auto, 100% auto, cover;
	background-position: left center, top right, bottom center, center center;
	background-repeat: no-repeat;
	.Popup-body {
		background-color: transparent;
		height: 100%;
	}
	.Popup-buttons {
		margin-top: 2em;
		display: block;
	}
	.Popup-text {
		width: 100%;
		font-size: 1em;
		padding: 2em 0.8em;
		background-color: $beige-light;
	}
}

/* Room intro popups */
.Popup.Popup--roomIntro1,
.Popup.Popup--roomIntro2,
.Popup.Popup--roomIntro3,
.Popup.Popup--roomIntro4 {
	background-image: url('../../assets/images/background-popup1.jpg');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	.Popup-body {
		background-color: transparent;
		height: 100%;
		@include flex('flex-start', center, 'column');
		padding-top: 18em;
		background-size: 12em auto;
		background-position: top 5em center;
		background-repeat: no-repeat;
	}
	.Popup-buttons {
		margin-top: 2em;
		display: block;
	}
	.Popup-title {
		padding: 0 0 0.5em 0;
		border-bottom: 2px solid $brown;
	}
	.Popup-text {
		width: 100%;
		font-size: 1em;
		line-height: 1.125em;
		padding: 1em 0.75em;
	}
	.Popup-pin-container {
		width: 12em;
		height: 13em;
		position: absolute;
		top: 5em;
	}
	.Popup-pin {
		position: absolute;
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
		width: 3.5em;
		height: 4.5em;
	}
}
.Popup.Popup--roomIntro1 .Popup-body {background-image: url('../../assets/images/area-1.png');}
.Popup.Popup--roomIntro2 .Popup-body {background-image: url('../../assets/images/area-2.png');}
.Popup.Popup--roomIntro3 .Popup-body {background-image: url('../../assets/images/area-3.png');}
.Popup.Popup--roomIntro4 .Popup-body {background-image: url('../../assets/images/area-4.png');}


/* Streak */
.Popup.Popup--streak {
	.Popup-body {
		position: relative;
		width: 100%;
		height: 100%;
		padding: 16em 2.5em 0 2.5em;
		background-color: transparent;
		background-image: url('../../assets/images/background-popup2.png');
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
	}
	.Popup-title {
		font-family: 'Frutiger';
		font-size: 2em;
		padding-bottom: 0.5em;
		color: $text-light;
	}
	.Popup-text {
		font-size: 1.5em;
		padding-bottom: 1em;
		color: $text-light;
		line-height: 1.2;
		font-weight: bold;
	}
	.Popup-buttons {
		margin-top: 1em;
		display: block;
		text-align: center;
		.Popup-button {
			display: inline-block;
			padding: 0.5em 0em;
			width: 8em;
		}
	}
	.Popup-points {
		color: $text-light;
		font-size: 2.1875em;
	}
}

/* Get clue in quiz popup */
.Popup.Popup--getClue {
	.Popup-body {
		position: relative;
		width: 100%;
		height: 100%;
		padding: 16em 2.5em 0 2.5em;
		background-color: transparent;
		background-image: url('../../assets/images/background-popup2.png');
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
	}
	.Popup-title {
		font-family: 'Frutiger';
		font-size: 2em;
		padding-bottom: 1em;
		color: $text-light;
	}
	.Popup-text {
		font-size: 1.5em;
		padding-bottom: 1.5em;
		color: $text-light;
		line-height: 1.2;
		font-weight: bold;
	}
	.Popup-buttons {
		.Popup-button {
			padding: 0.75em 0em;
			width: 4em;
		}
	}
}

/* Quiz image */
.Popup.Popup--quizImage {
	@include flex('center', 'center');
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url('../../assets/images/background-cork.jpg');
		background-size: cover;
		opacity: 0.07;
	}
	.Popup-body {
		width: 80%;
		background-color: transparent;
		padding: 0;
		@include flex('center', 'center');
		@include corner-box();
	}
	.Popup-image {
		background-color: $beige-light;
		padding: 4em 0;
	}
}

/* Challenge completed */
.Popup.Popup--challengeCompleted {
	background-color: rgba($beige-light, 0.65);
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url('../../assets/images/background-cork.jpg');
		background-size: cover;
		opacity: 0.07;
	}
	.Popup-body {
		min-height: 100%;
		@include flex('center', 'center');
		padding: 0;
		background-color: transparent;
	}
	.Popup-buttons {
		width: 100%;
		position: absolute;
		left: 0;
		bottom: 6.5em;
		display: block;
	}
}

/* Room completed */
.Popup.Popup--roomCompleted {
	.Popup-body {
		position: relative;
		width: 100%;
		height: 100%;
		padding: 16em 2.5em 0 2.5em;
		background-color: transparent;
		background-image: url('../../assets/images/background-popup2.png');
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
	}
	.Popup-title {
		font-family: 'Frutiger';
		color: $text-light;
		font-size: 2.2em;
		padding-bottom: 0.25em;
	}
	.Popup-text {
		font-size: 1.5em;
		color: $text-light;
	}
	.Popup-points {
		color: $text-light;
		font-size: 2.1875em;
	}
}

/* All rooms completed */
.Popup.Popup--allRoomsCompleted {
	.Popup-body {
		position: relative;
		width: 100%;
		height: 100%;
		padding: 16em 1.5em 0 1.5em;
		background-color: transparent;
		background-image: url('../../assets/images/background-popup2.png');
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
	}
	.Popup-title {
		font-family: 'Frutiger';
		color: $text-light;
		font-size: 2.2em;
		padding-bottom: 0.5em;
	}
	.Popup-text {
		font-size: 1.5em;
		color: $text-light;
	}
	.Popup-icon {
		width: 100%;
		height: 7em;
		background-size: 5em auto;
		background-position: center center;
		background-repeat: no-repeat;
		&--trophy {
		}
	}
}

/* Bonus room intro */
.Popup.Popup--bonus {
	.Popup-body {
		position: relative;
		width: 100%;
		height: 100%;
		padding: 0;
		background-color: transparent;
		background-image: url('../../assets/images/background-popup2.png');
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
	}
	.Popup-text {
		position: absolute;
		top: 16%;
		left: 0;
		bottom: 0;
		width: 100%;
		font-size: 1.5em;
		line-height: 1;
		padding: 9em 2.5em 0em 2.5em;
		color: $text-light;
		background-image: url('../../assets/images/bonus.svg');
		background-size: 8em auto;
		background-position: top center;
		background-repeat: no-repeat;
	}
	.Popup-buttons {
		position: absolute;
		left: 0;
		width: 100%;
		top: 70%;
		display: inline-block;
		text-align: center;
		
	}
}