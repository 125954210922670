@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Phone {
	display: inline-block;

	.Phone-logo-1 {
		background-image: url('../../assets/images/phone/phone-1.svg');
		width: 10em;
		height: 15em;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
	}

	.Phone-logo-2 {
		background-image: url('../../assets/images/phone/phone-2.svg');
		width: 12em;
		height: 13em;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
	}

	.Phone-logo-3 {
		background-image: url('../../assets/images/phone/phone-3.svg');
		width: 12em;
		height: 13em;
		margin-right: 0.45em;
		margin-left: -0.45em;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
	}

	.Phone-logo-4 {
		background-image: url('../../assets/images/phone/phone-4.svg');
		width: 12em;
		height: 13em;
		margin-right: 1em;
		margin-left: -1em;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
	}
	
	.Phone-logo-5 {
		background-image: url('../../assets/images/phone/phone-5.svg');
		width: 13em;
		height: 13em;
		margin-right: 0.8em;
		margin-left: -1.8em;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
	}
	
	.Phone-logo-6 {
		background-image: url('../../assets/images/phone/phone-6.svg');
		width: 13em;
		height: 13em;
		margin-right: 0.8em;
		margin-left: -1.8em;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
	}
}