@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Pairs {
	height: 100%;
	width: 27em;
	&--paused {.Pairs-answer {cursor: not-allowed;}}
	// &--pairs-1 {
	// 	.Pairs-card {
	// 		height: 4.6em;
	// 	}
	// }

	.Pairs-header {
		@include no-select();
		color: $text-light;
		position: relative;
		border-radius: 0.5em;
		z-index: 1;
		background-size: cover;
		
		.Pairs-headerContent{
			height: 10em;
			padding: 1.5em 2em 1em 1em;
			border-radius: 0.7em;
			background-color: rgba($blue-light, 40%);

			.Pairs-question {
				font-size: 1.125em;
				font-weight: normal;
				line-height: 1.2;
				text-align: center;
				color: $text-light;
				p {display: inline; margin: 0;}
			}

			.Pairs-questionHeader {
				@include no-select();
				text-align: center;
				padding-top: 0.5em;
				padding-bottom: 0.5em;
				background-color: $blue-dark;
				border-radius: 0.5em;
				box-shadow:  0.3em 0.3em 0.3em #0000002a;
				width: 9.5em;
				position: absolute;
				left: -1.5em;
				top: -1em;
				.Pairs-questionHeaderText {
					font-size: 1.25em;
					font-weight: bold;
				}
			}
		}
	}

	.Pairs-body {
		color: $text-light;
		position: relative;
		z-index: 0;
		margin-top: 2em;

		.Pairs-instructions {
			@include no-select();
			margin-bottom: 1em;
			margin-left: 1em;
			position: relative;
			text-align: start;
			font-weight: bold;
		}

		.Pairs-columnWrapper {
			@include flex('', '', 'row');

			.Pairs-verticalLine {
				width: 0.25em;
				margin-left: 0.75em;
				margin-right: 0.75em;
				margin-bottom: 0.5em;
				background-color: $turquoise;
				border-radius: 0.5em;
				position: relative;
			}
		
			.Pairs-column {
				height: 100%;
				display: inline-block;
				vertical-align: top;
				width: 50%;
		
				.Pairs-card {
					@include no-select();
					cursor: pointer;
					font-size: 0.9em;
					line-height: 1.1;
					text-align: center;
					background-color: $turquoise;
					border-color: $turquoise-light;
					border-style: solid;
					border-width: 0.1em;
					padding: 0.5em;
					border-radius: 0.7em;
					margin-bottom: 0.75em;
					//height: 5em;
					overflow: hidden;
					@include translate(0, 0);
					p {display: inline; margin: 0;}
					@include box-shadow(0, 0.1876em, 0.25em, 0, rgba(#000000, 0.16));
					&--bgImg {
						@include flex('center', 'flex-start');
					}	
					&--selected {
						background-color: $turquoise-select;
						border-color: $turquoise-select-light;
					}
					&--correct {
						color: $text-light;
						background-color: $green;
						border-color: $green-light;
						-webkit-animation: bounce 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
						-webkit-animation-fill-mode: forwards;
						animation: bounce 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
						animation-fill-mode: forwards;
					}
					&--wrong {
						color: $text-light;
						background-color: $red;
						border-color: $red-light;
						-webkit-animation: wobble 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
						animation: wobble 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
					}
				}
			}
		}
	}
}