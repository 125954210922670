@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Hexagon{
	position: relative;
	background-image: url('../../assets/images/hexagon/hexagon.svg');
	width: 22em;
	height: 19em;
	bottom: 2em;
	margin-left: 3em;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
	font-weight: 400;

	.Hexagon-text-0 {
		display: none;
	}

	.Hexagon-inner-0 {
		@include flex('center', '', 'column');
		@include no-select();
		height: 100%;
		padding-right: 0.5em;
		padding-bottom: 0.5em;
		color: $text-light;
		text-align: center;

		.Hexagon-text-1 { // Bronze
			.Hexagon-text-header {
				font-size: 2.6em;
			}
			.Hexagon-text-body {
				font-weight: 500;
				font-size: 1.8em;
				-webkit-text-stroke-color: $bronze-light;
				-webkit-text-stroke-width: 0.03em;

				.Hexagon-trophy { 
					font-weight: normal;
					color: $bronze;
				}
			}
		}

		.Hexagon-text-2 { // Silver
			.Hexagon-text-header {
				font-size: 2.6em;
			}
			.Hexagon-text-body {
				font-weight: 500;
				font-size: 1.8em;
				-webkit-text-stroke-color: $silver-light;
				-webkit-text-stroke-width: 0.03em;

				.Hexagon-trophy { 
					font-weight: normal;
					color: $silver;
				}
			}
		}

		.Hexagon-text-3 { // Gold
			.Hexagon-text-header {
				font-size: 2.6em;
			}
			.Hexagon-text-body {
				font-weight: 500;
				font-size: 1.8em;
				-webkit-text-stroke-color: $gold-light;
				-webkit-text-stroke-width: 0.03em;

				.Hexagon-trophy { 
					font-weight: normal;
					color: $gold;
				}
			}
		}
	}

	.Hexagon-inner-1 {
		position: absolute;
		bottom: 1.5em;
		left: -2.35em;
		background-image: url('../../assets/images/hexagon/hexagon-inner-1.svg');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
		width: 28em;
		height: 25.5em;
	}

	.Hexagon-inner-2 {
		position: absolute;
		bottom: 1.5em;
		left: -1.75em;
		background-image: url('../../assets/images/hexagon/hexagon-inner-2.svg');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
		width: 24em;
		height: 21em;
	}

	.Hexagon-inner-3 {
		position: absolute;
		bottom: 1.5em;
		left: -4.5em;
		background-image: url('../../assets/images/hexagon/hexagon-inner-3.svg');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
		width: 24em;
		height: 20.5em;
	}

	.Hexagon-inner-4 {
		position: absolute;
		bottom: 1.5em;
		left: -1em;
		background-image: url('../../assets/images/hexagon/hexagon-inner-4.svg');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
		width: 20em;
		height: 23em;
	}

	.Hexagon-inner-5 {
		position: absolute;
		bottom: 1.5em;
		left: -1em;
		background-image: url('../../assets/images/hexagon/hexagon-inner-5.svg');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
		width: 23em;
		height: 28em;
	}

	.Hexagon-inner-6 {
		position: absolute;
		bottom: 1.5em;
		left: -2em;
		background-image: url('../../assets/images/hexagon/hexagon-inner-6.svg');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
		width: 23em;
		height: 26em;
	}

	.Hexagon-inner-7 {
		position: absolute;
		bottom: -4.9em;
		left: -1.4em;
		background-image: url('../../assets/images/hexagon/hexagon-inner-7.svg');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
		width: 25em;
		height: 32em;
	}

	.Hexagon-inner-8 {
		position: absolute;
		bottom: 1.5em;
		left: -1.1em;
		background-image: url('../../assets/images/hexagon/hexagon-inner-8.svg');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
		width: 22em;
		height: 25em;
	}

	.Hexagon-inner-9 {
		position: absolute;
		bottom: 1.5em;
		left: -2.4em;
		background-image: url('../../assets/images/hexagon/hexagon-inner-9.svg');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
		width: 24em;
		height: 21em;
	}

	.Hexagon-inner-10 {
		position: absolute;
		bottom: 1.5em;
		left: 1em;
		background-image: url('../../assets/images/hexagon/hexagon-inner-10.svg');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
		width: 19em;
		height: 23em;
	}

	.Hexagon-inner-11 {
		position: absolute;
		bottom: 1.5em;
		left: -0.5em;
		background-image: url('../../assets/images/hexagon/hexagon-inner-11.svg');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
		width: 22em;
		height: 21em;
	}

	.Hexagon-inner-12 {
		position: absolute;
		bottom: 1.5em;
		left: -0.5em;
		background-image: url('../../assets/images/hexagon/hexagon-inner-12.svg');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
		width: 22em;
		height: 21em;
	}

	.Hexagon-inner-13 {
		position: absolute;
		bottom: 1.5em;
		left: 0.5em;
		background-image: url('../../assets/images/hexagon/hexagon-inner-13.svg');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
		width: 19em;
		height: 21.5em;
	}

	.Hexagon-inner-14 {
		position: absolute;
		bottom: 1.5em;
		left: -1em;
		background-image: url('../../assets/images/hexagon/hexagon-inner-14.svg');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
		width: 23em;
		height: 25em;
	}
}