@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.LanguageButton {
	position: relative;

	.LanguageButton-icon {
		width: 2.75em;
		height: 2.75em;
		cursor: pointer;

		&.da {
			background-image: url(../../assets/images/icon-flag-danish.svg);
			background-size: contain;
		}

		&.en {
			background-image: url(../../assets/images/icon-flag-english.svg);
			background-size: contain;
		}
	}
}