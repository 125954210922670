@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.FramedBox {
	display: block;
	border-width: 1em;
	border-style: solid;
	border-image: url('../../assets/images/background-frame.png') round;
	border-image-slice: 50 50 50 50;
	border-image-width: 1em 1em;
	padding: 0;
}