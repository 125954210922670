@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.TrophyRoom {
	@include defaultPage();
    @include flex('', '', 'row');
	background-image: url('../../assets/images/background-roomOverview.svg');
	background-size: cover;
    background-position: bottom right;
	background-repeat: no-repeat;
    position: relative;
	height: 100%;

	.TrophyRoom-languageButton {
		position: absolute;
        z-index: 99;
		top: 1em;
		right: 1em;
	}

	.TrophyRoom-sidebar {
        @include flex('', '', 'column');
		margin-left: 1em;
		margin-top: 1em;
		position: relative;
        z-index: 50;
		
		.TrophyRoom-sidebarLogo:hover {
			cursor: pointer;
		}

		.TrophyRoom-sidebarLogo {
			width: 11em;
			height: 7em;
			margin-bottom: 1em;
			margin-left: -1em;
			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-position: center center;
			background-image: url('../../assets/images/datadyst-logo.svg');

            &.en {
    			background-image: url('../../assets/images/datadyst-logo-english.svg');
            }
		}
	
		.TrophyRoom-sidebarRoomButtons {
			margin-top: 1.5em;
			margin-bottom: 4em;
			width: 10em;
		}
	}

    .TrophyRoom-confettiWrapper {
        pointer-events: none;
        .TrophyRoom-fallingConfetti {
            position: absolute;
            top: -1em;
            @include fall(-1em, 100vh, 5);
            .TrophyRoom-rotatingConfetti {
                width: 0.3em;
                height: 1em;
                @include rotate3D(0, 1, 0.3, 0.5s);
            }
        }
    }

	.TrophyRoom-logoWrapper {
		@include flex('space-between', '', 'column');
        width: 100%;
        z-index: 50;
		.TrophyRoom-hexagon {
			@include flex('', '', '');
			position: relative;
            @include align-self(flex-end);
			right: 7em;
			top: 12em;
			z-index: 0;
		}
	
		.TrophyRoom-datatilsynetLogo {
			@include flex('', '', '');
            @include align-self(flex-end);
			position: relative;
			min-width: 16.48em;
			min-height: 3.6848em;
			width: 16.48em;
			height: 3.6848em;
			bottom: 3em;
			right: 3em;
			background-size: cover;
			background-image: url('../../assets/images/icon-datatilsynet-logo.svg');
		}
	}

    .TrophyRoom-wrapper {
        width: 100%;
        @include flex('space-between', 'center', 'column');
        .TrophyRoom-headerWrapper {
            @include flex('', '', 'column');
            @include no-select();
            position: relative;
            margin-left: 4em;
            margin-top: 2em;
            .TrophyRoom-header {
                @include flex('', '', 'row');
                position: relative;
                text-align: center;
                z-index: 2;

                .TrophyRoom-headerStatus {
                    @include flex('', 'center', 'row');
                    position: relative;
                    text-align: center;
                    z-index: 2;
                    
                    .TrophyRoom-line {
                        width: 100%;
                        position: absolute;
                        z-index: 1;
                        height: 0.05em;
                        padding-left: 0.25em;
                        padding-right: 0.25em;
                        background-color: $turquoise;
                    }

                    .TrophyRoom-challengeStatus {
                        position: relative;
                        z-index: 2;
                        display: inline-block;
                        width: 1.25em;
                        height: 1.5em;
                        background-image: url('../../assets/images/hexagon/hexagon-challenge-selected.svg');
                        background-size: contain;
                        background-position: center center;
                        background-repeat: no-repeat;
                        &--notFirst {margin-left: 0.5em;}
                    }
                }
                .TrophyRoom-counter {
                    color: $turquoise;
                    @include align-self(center);
                    margin-left: 1em;
                }
            }
        }

        .TrophyRoom-bodyWrapper {
            z-index: 50;
            @include flex('', 'center', 'column');
            position: relative;
            margin-left: 1em;
            width: 100%;

            .TrophyRoom-confetti {
                position: relative;
                background-image: url('../../assets/images/trophyRoom/trophy-confetti.svg');
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                width: 30em;
                height: 30.5em;

                .TrophyRoom-trophy-1 {
                    position: relative;
                    background-image: url('../../assets/images/trophyRoom/trophy-bronze.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 17.5em;
                    height: 24.25em;
                    top: 4em;
                    left: 6.5em;
                }
                .TrophyRoom-trophy-2 {
                    position: relative;
                    background-image: url('../../assets/images/trophyRoom/trophy-silver.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 17.5em;
                    height: 24.25em;
                    top: 4em;
                    left: 6.5em;
                }
                .TrophyRoom-trophy-3 {
                    position: relative;
                    background-image: url('../../assets/images/trophyRoom/trophy-gold.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 17.5em;
                    height: 24.25em;
                    top: 4em;
                    left: 6.5em;
                }
            }
        }

        .TrophyRoom-buttons {
            z-index: 50;
            @include flex('', '', 'row');
            @include no-select();
            margin-bottom: 3em;
            .TrophyRoom-button:hover {
                cursor: pointer;
            }
            .TrophyRoom-button {
                width: 10em;
                @include align-self(center);
                color: $text-light;
                line-height: 1.1;
                padding: 0.5em;
                text-align: center;
                background-color: $turquoise;
                border-color: $turquoise-light;
                border-style: solid;
                border-width: 0.1em;
                border-radius: 0.7em;
                margin-left: 1em;
            }
        }
    }
}
