@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Trophy {
	@include flex('center', '', '');
	.Trophy-0 {
		width: 1.5em;
		height: 2em;
		background-image: url('../../assets/images/trophy/icon-trophy-empty.svg');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
	}
	.Trophy-1 {
		margin-top: -2.5em;
		height: 7em;
		width: 7.5em;
		background-image: url('../../assets/images/trophy/icon-trophy-bronze.svg');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
	}
	.Trophy-2 {
		margin-top: -2.5em;
		height: 7em;
		width: 7.5em;
		background-image: url('../../assets/images/trophy/icon-trophy-silver.svg');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
	}
	.Trophy-3 {
		margin-top: -2.5em;
		height: 7em;
		width: 7.5em;
		background-image: url('../../assets/images/trophy/icon-trophy-gold.svg');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
	}
}