@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Room {
	@include defaultPage();
	min-height: 43.2em;
	height: 43.2em;
	
	.Room-body {
		padding: 2em;
		color: white;
	}
}