@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Sort {
	width: 27em;
	height: 100%;
	&--paused {.Sort-item {cursor: not-allowed;}}
	&--sort-5,
	&--sort-6 {
		.Sort-boxes {
			justify-content: space-between;
		}
		.Sort-boxContainer {
			background: $olive-dark;
			width: calc(50% - 0.5em);
		}
		.Sort-boxText {
			font-size: 1.2em;	
			padding: 0.25em 0;
			span {
				display: block; 
				font-size: 0.8em;
				margin-top: 0.25em;
				text-transform: none;
				font-weight: normal;
			}
		
		}
	}

	.Sort-header {
		@include no-select();
		color: $text-light;
		position: relative;
		border-radius: 0.5em;
		z-index: 1;
		background-size: cover;

		.Sort-headerContent {
			height: 10em;
			padding: 1.5em 2em 1em 1em;
			border-radius: 0.7em;
			background-color: rgba($blue-light, 40%);

			.Sort-questionHeader {
				@include no-select();
				text-align: center;
				padding-top: 0.5em;
				padding-bottom: 0.5em;
				background-color: $blue-dark;
				border-radius: 0.5em;
				box-shadow:  0.3em 0.3em 0.3em #0000002a;
				width: 9.5em;
				position: absolute;
				left: -1.5em;
				top: -1em;
				.Sort-questionHeaderText {
					font-size: 1.25em;
					font-weight: bold;
				}
			}
		
			.Sort-question {
				font-size: 1.125em;
				font-weight: normal;
				line-height: 1.2;
				text-align: center;
				color: $text-light;
				p {display: inline; margin: 0;}
			}
		}
	}

	.Sort-body {
		color: $text-light;
		position: relative;
		z-index: 0;
		margin-top: 2em;
		
		.Sort-instructions {
			@include no-select();
			margin-bottom: 1em;
			margin-left: 1em;
			position: relative;
			text-align: start;
			font-weight: bold;
			color: $text-light;
		}
	
		.Sort-item:hover {
			cursor: pointer;
		}
	
		.Sort-items {
			.Sort-item {
				@include flex('center', 'center');
				@include no-select();
				line-height: 1.1;
				text-align: center;
				background-color: $turquoise;
				border-color: $turquoise-light;
				border-style: solid;
				border-width: 0.1em;
				padding: 0.5em;
				border-radius: 0.7em;
				margin-bottom: 0.5em;
				//height: 5em;
				overflow: hidden;
				@include translate(0, 0);
				p {display: inline; margin: 0;}
				@include box-shadow(0, 0.1876em, 0.25em, 0, rgba(#000000, 0.16));
				img {
					position: absolute;
					left: 0.5em;
					max-width: 5.625em;
					max-height: 80%;
				}
				&--selected {
					background-color: $turquoise-select;
					border-color: $turquoise-select-light;
				}
				&--placed {display: none;}
				&--correct {
					background-color: $green;
					border-color: $green-light;
					
					-webkit-animation: bounce 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
					-webkit-animation-fill-mode: forwards;
					animation: bounce 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
					animation-fill-mode: forwards;
				}
				&--wrong {
					color: $text-light;
					background-color: $red;
					border-color: $red-light;
					-webkit-animation: wobble 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
					-webkit-animation-fill-mode: forwards;
					animation: wobble 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
					animation-fill-mode: forwards;
				}
			}
		}
	
		.Sort-boxes {
			margin-bottom: 1em;
			@include flex('space-between', 'center');
	
			.Sort-boxContainer:hover { 
				cursor: pointer;
			}
			.Sort-boxContainer {
				display: inline-block;
				background-color: $orange;
				border-radius: 0.5em;
				height: 3em;
				width: 12em;
				padding: 0.5em 0.25em;
				&--red {background-color: $red;}
				&--green {background-color: $green;}
				@include flex('center', 'center');
				
			}
			.Sort-boxText {
				text-align: center;
				color: $text-light;
				font-size: 1em;	
				text-transform: uppercase;
				font-weight: bold;
				p {display: inline; margin: 0;}
				@include no-select();
			}
		}
	}
}