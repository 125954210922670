@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Order {
	height: 100%;
	&--paused {.Order-item {cursor: not-allowed;}}
}

.Order-header {
	position: relative;
	position: fixed;
	top: 4.5em;
	left: 1em;
	right: 1em;
	padding: 0.5em 0.75em 0 0.75em;
	text-align: center;
	z-index: 1;
	border-top: 1px solid rgba($olive, 0.5);
	border-bottom: 1px solid rgba($olive, 0.5);
	background-size: cover;
}

.Order-question {
	position: relative;
	font-size: 1.125em;
	line-height: 1.2;
	text-align: center;
	color: $text-dark;
	background-color: $beige-light;
	padding: 0 0.5em;
	p {display: inline; margin: 0;}
}

.Order-boxes {
	position: relative;
	margin-top: 1em;
	padding-bottom: 0.5em;
	
	// @include flex('space-around', 'center');
	// &::before {
	// 	content: '';
	// 	width: calc(100% - 2em);
	// 	height: 0.25em;
	// 	background-color: #EFBE57;
	// 	position: absolute;
	// 	top: 1.35em;
	// }
}

.Order-boxContainer {
	z-index: 1;
	position: relative;
	display: inline-block;
	width: 3em;
	height: 3em;
	margin: 0 0.4em;
	background-color: $orange;
	&--correct {
		background-color: $olive;
	}
	&::after {
		content: '';
		position: absolute;
		left: 3.1em;
		top: 0;
		width: 0.6em;
		height: 100%;
		background-image: url('../../../assets/images/icon-arrow.svg');
		background-size: 0.6em auto;
		background-position: center center;
		background-repeat: no-repeat;
	}
	&:nth-child(1) {margin-left: 0;}
	&:nth-last-child(1) {
		margin-right: 0;
		&::after {display: none;}
	}
}
.Order-boxText {
	@include flex('center', 'center');
	height: 100%;
	font-size: 2em;
	color: $text-light;
	font-weight: bold;
	@include no-select();
}

.Order-body {
	position: relative;
	padding: 0 1em;
	padding-top: calc(1 * 16 * ((100vw / 360)));
	margin: 0 1em;
	z-index: 0;
}

.Order-itemWrap {
	position: relative;
	display: block;
	height: 3em;
	width: 60%;
	margin: auto;
	margin-top: 0.5em;
}

.Order-item {
	position: relative;
	@include flex('center', 'center');
	width: 100%;
	height: 100%;
	line-height: 1.1;
	text-align: center;
	color: $text-dark;
	background-color: $beige;
	padding: 0.25em;
	overflow: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	border: 1px solid $olive;
	p {display: inline; margin: 0;}
	@include box-shadow(0, 0.1876em, 0.25em, 0, rgba(#000000, 0.16));
	@include transition(padding, 0.1s, linear);
	&--selected {background-color: $beige-dark;}
	&--placed {
		color: $text-light;
		background-color: $olive;
	}
	&--correct {
		color: $text-light;
		background-color: $olive;
		-webkit-animation: bounce 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
		-webkit-animation-fill-mode: forwards;
		animation: bounce 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
		animation-fill-mode: forwards;
	}
	&--wrong {
		color: $text-light;
		background-color: $red;
		-webkit-animation: wobble 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
		-webkit-animation-fill-mode: forwards;
		animation: wobble 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
		animation-fill-mode: forwards;
	}
}

.Order-itemNumber {
	font-size: 2em;
	position: absolute;
	top: 0.25em;
	left: 0;
	width: 1em;
	height: 1em;
	z-index: 1;
	color: $beige-light;
	font-weight: bold;
	border-right: 1px solid $beige-light;
	line-height: 1;
	text-align: center;
}